import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, Table, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import connection from "../axios/connection";
import { getAllGroups } from "../services/service";
import styled from "styled-components";

const Groups = () => {
  const [loading, setLoading] = useState(true);
  const [groupName, setGroupName] = useState("");
  const [contacts, setContacts] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns = [
    {
      title: "Group",
      dataIndex: "name",
      key: "name",
    },
  ];

  useEffect(() => {
    setLoading(true);
    getAllGroups().then((grs) => {
      setAllGroups(grs ? grs.groups : []);
      setLoading(false);
    });
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const contactsList = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

      const formattedContacts = contactsList.map((row) => `${row[0]}@c.us`);
      setContacts(formattedContacts);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = async (values) => {
    try {
      const response = await connection.post("createGroup", {
        groupName,
        contacts,
      });

      if (response.data.status === 200) {
        alert("Group created successfully!");
        setIsModalVisible(false);
      } else {
        alert("Failed to create group: " + response.data.message);
      }
    } catch (error) {
      console.error("Error creating group:", error);
      alert("Error creating group");
    }
  };

  const tableRowOnClick = (record) => {
    setSelectedGroup(record);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <h2>Total group(s): {allGroups.length}</h2>
        <Button type="primary" onClick={showModal} style={{ marginTop: "8%" }}>
          Create Group
        </Button>
      </div>
      <TableContainer>
        <DetailsWrapper>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "16px",
              flexWrap: "wrap",
            }}
          >
            <h3>Total member(s): {selectedGroup?.participants?.length}</h3>
            <Button type="primary">Add Member</Button>
          </div>
          <GroupList>
            {selectedGroup?.participants?.map((participant) => (
              <Participant key={participant.id.user}>
                <div>{participant.id.user}</div>
                <div>{participant.isAdmin ? " (Admin)" : ""}</div>
              </Participant>
            ))}
          </GroupList>
          <TableWrapper>
            <Table
              columns={columns}
              dataSource={allGroups}
              loading={loading}
              rowKey="name"
              scroll={{ y: 240 }}
              onRow={(record) => ({
                onClick: () => tableRowOnClick(record),
                style: { cursor: "pointer" },
              })}
            />
          </TableWrapper>
        </DetailsWrapper>
      </TableContainer>
      <Modal
        title="Create Group"
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        width="90%"
        style={{ top: 0 }}
        bodyStyle={{ padding: "16px" }}
        centered
      >
        <CardContainer>
          <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              label="Group Name"
              name="groupName"
              rules={[
                { required: true, message: "Please enter the group name!" },
              ]}
            >
              <Input
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                autoFocus
              />
            </Form.Item>

            <Form.Item
              label="Upload contacts (Excel file)"
              name="file"
              rules={[
                { required: true, message: "Please upload an Excel file!" },
              ]}
            >
              <Upload
                accept=".xlsx, .xls"
                beforeUpload={() => false} // Prevent auto-upload
                onChange={handleFileUpload}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Create Group
              </Button>
            </Form.Item>
          </Form>
        </CardContainer>
      </Modal>
    </Container>
  );
};

export default Groups;

const Container = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  padding: 16px;
  background-color: #f0f2f5;
  margin: 0 auto;
  max-width: 1200px;
  margin-left: 235px;
  @media (max-width: 1200px) {
    margin-left: 0px; // Adjust margin for smaller screens
  }
`;

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-wrap: wrap;
`;

const TableWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 16px;
`;

const DetailsWrapper = styled.div`
  flex: 1;
  margin-left: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  overflow: auto;
`;

const CardContainer = styled.div`
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  margin: 0 auto;
`;

const GroupList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Participant = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;
