import { useState } from "react";
import {
  Button,
  Grid,
  Box,
  Typography,
  Paper,
  TextField,
  CssBaseline,
  IconButton,
  InputAdornment,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import bgpic from "../assets/login.png";
import styled from "styled-components";
import Popup from "../components/Popup";

const defaultTheme = createTheme();

const LoginPage = ({ role, onLogin }) => {
  const navigate = useNavigate();

  const [toggle, setToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const staticEmail = "admin@tz.in";
  const staticPassword = "123";

  const handleSubmit = (event) => {
    event.preventDefault();
    const email = event.target.email?.value;
    const password = event.target.password?.value;

    setEmailError(false);
    setPasswordError(false);
    setMessage("");

    if (!email || !password) {
      if (!email) setEmailError(true);
      if (!password) setPasswordError(true);
      return;
    }

    if (email === staticEmail && password === staticPassword) {
      setLoader(true);
      setTimeout(() => {
        onLogin();
        localStorage.setItem("isLogedIn", "true");
        navigate("/"); // Navigate to the Dashboard
      }, 1000); // Simulate a loading delay
    } else {
      setMessage("Invalid Email or Password");
      setShowPopup(true);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh", mr: "50%" }}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: { xs: "90%", sm: "80%", md: "70%", lg: "50%" },
            }}
          >
            <Typography variant="h4" sx={{ mb: 2, color: "#2c2143" }}>
              {role} Login
            </Typography>
            <Typography variant="body1" align="center" sx={{ mb: 3 }}>
              Welcome back! Please enter your details
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 2 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Enter your email"
                name="email"
                autoComplete="email"
                autoFocus
                error={emailError}
                helperText={emailError && "Email is required"}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={toggle ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                error={passwordError}
                helperText={passwordError && "Password is required"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setToggle(!toggle)}>
                        {toggle ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <LightPurpleButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3 }}
                disabled={loader} // Disable button when loading
              >
                {loader ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Login"
                )}
              </LightPurpleButton>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs
          sm={4}
          md={5}
          sx={{
            backgroundImage: `url(${bgpic})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
            width: "100%", // Ensure background covers full height
          }}
        />
        <Popup
          openPopup={showPopup}
          setOpenPopup={setShowPopup}
          title="Error"
          message={message}
        />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
    </ThemeProvider>
  );
};

const LightPurpleButton = styled(Button)`
  background-color: #3e79fa;
  &:hover {
    background-color: #2b61d9;
  }
`;

export default LoginPage;
