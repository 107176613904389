import React from "react";
import styled from "styled-components";
import user from "../assets/user.png";

const Profile = () => {
  return (
    <ProfileContainer>
      <ProfileCard>
        <ProfileImage src={user} alt="Profile" />
        <ProfileName>Dhaneswar</ProfileName>
        <ProfileInfo>
          <strong>Email:</strong> dhaneswarsetha.123@gmail.com
        </ProfileInfo>
      </ProfileCard>
    </ProfileContainer>
  );
};

const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
`;

const ProfileCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 10px 7px 20px rgba(95, 85, 85, 0.65);
  text-align: center;
  max-width: 400px;
  width: 90%;
  @media (max-width: 480px) {
    padding: 15px;
  }
`;

const ProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  filter: drop-shadow(10px 7px 10px orange);
  margin-bottom: 20px;
`;

const ProfileName = styled.h2`
  margin: 20px 0 10px;
  color: black;
  font-size: 1.5rem;
`;

const ProfileInfo = styled.p`
  color: #555;
  margin-bottom: 5px;
  font-size: 1rem;
  line-height: 1.5;
`;

export default Profile;
